import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

// components
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import Contatti from "../components/contatti";

// markup
const ContattiPage = () => {
    return (
        <Page
            title="Contatti"
            description="Farmacia Settimo Miglio Dott.ssa Annamaria Caobelli - Via A. Bertoldi 84 - 37026 Settimo di Pescantina (Vr) - Tel: 045 7150797 - WhatsApp: 327 2317444"
        >
            <Hero
                color="#fff"
                title="Contatti"
                description=""
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio contatti"
                        layout="fullWidth"
                    />
                }
            />

            <Contatti />
        </Page>
    );
};

export default ContattiPage;
